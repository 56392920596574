import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

const Datenschutzerklaerung = () => (
    <InnerPage
        title="Datenschutzerklärung"
        description="Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), […]"
    >
        <IntroHolder
            title="Datenschutzerklärung"
            breadcrumbs={{
                'current-item': 'Datenschutzerklärung',
            }}
        />

        <TwoColumns>
            <Content>
                <p>
                    Verantwortliche Stelle im Sinne der Datenschutzgesetze,
                    insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
                </p>
                <p>Nationaler Geistiger Rat der Bahá’i in Deutschland</p>
                <h2>Ihre Betroffenenrechte</h2>
                <p>
                    Unter den angegebenen Kontaktdaten unseres
                    Datenschutzbeauftragten können Sie jederzeit folgende Rechte
                    ausüben:
                </p>
                <ul>
                    <li>
                        Auskunft über Ihre bei uns gespeicherten Daten und deren
                        Verarbeitung,
                    </li>
                    <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                    <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                    <li>
                        Einschränkung der Datenverarbeitung, sofern wir Ihre
                        Daten aufgrund gesetzlicher Pflichten noch nicht löschen
                        dürfen,
                    </li>
                    <li>
                        Widerspruch gegen die Verarbeitung Ihrer Daten bei uns
                        und
                    </li>
                    <li>
                        Datenübertragbarkeit, sofern Sie in die
                        Datenverarbeitung eingewilligt haben oder einen Vertrag
                        mit uns abgeschlossen haben.
                    </li>
                </ul>
                <p>
                    Sofern Sie uns eine Einwilligung erteilt haben, können Sie
                    diese jederzeit mit Wirkung für die Zukunft widerrufen.
                </p>
                <p>
                    Sie können sich jederzeit mit einer Beschwerde an die für
                    Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige
                    Aufsichtsbehörde richtet sich nach dem Bundesland Ihres
                    Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung.
                    Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
                    Bereich) mit Anschrift finden Sie unter:{' '}
                    <a
                        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                    </a>
                    .
                </p>
                <h2>
                    Zwecke der Datenverarbeitung durch die verantwortliche
                    Stelle und Dritte
                </h2>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten nur zu den in
                    dieser Datenschutzerklärung genannten Zwecken. Eine
                    Übermittlung Ihrer persönlichen Daten an Dritte zu anderen
                    als den genannten Zwecken findet nicht statt. Wir geben Ihre
                    persönlichen Daten nur an Dritte weiter, wenn:
                </p>
                <ul>
                    <li>
                        Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,
                    </li>
                    <li>
                        die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen
                        erforderlich ist,
                    </li>
                    <li>
                        die Verarbeitung zur Erfüllung einer rechtlichen
                        Verpflichtung erforderlich ist,
                    </li>
                </ul>
                <p>
                    die Verarbeitung zur Wahrung berechtigter Interessen
                    erforderlich ist und kein Grund zur Annahme besteht, dass
                    Sie ein überwiegendes schutzwürdiges Interesse an der
                    Nichtweitergabe Ihrer Daten haben.
                </p>
                <h2>Löschung bzw. Sperrung der Daten</h2>
                <p>
                    Wir halten uns an die Grundsätze der Datenvermeidung und
                    Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten
                    daher nur so lange, wie dies zur Erreichung der hier
                    genannten Zwecke erforderlich ist oder wie es die vom
                    Gesetzgeber vorgesehenen vielfältigen Speicherfristen
                    vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf
                    dieser Fristen werden die entsprechenden Daten routinemäßig
                    und entsprechend den gesetzlichen Vorschriften gesperrt oder
                    gelöscht.
                </p>
                <h2>
                    Erfassung allgemeiner Informationen beim Besuch unserer
                    Website
                </h2>
                <p>
                    Wenn Sie auf unsere Website zugreifen, werden automatisch
                    mittels eines Cookies Informationen allgemeiner Natur
                    erfasst. Diese Informationen (Server-Logfiles) beinhalten
                    etwa die Art des Webbrowsers, das verwendete Betriebssystem,
                    den Domainnamen Ihres Internet-Service-Providers und
                    ähnliches. Hierbei handelt es sich ausschließlich um
                    Informationen, welche keine Rückschlüsse auf Ihre Person
                    zulassen.
                </p>
                <p>
                    Diese Informationen sind technisch notwendig, um von Ihnen
                    angeforderte Inhalte von Webseiten korrekt auszuliefern und
                    fallen bei Nutzung des Internets zwingend an. Sie werden
                    insbesondere zu folgenden Zwecken verarbeitet:
                </p>
                <ul>
                    <li>
                        Sicherstellung eines problemlosen Verbindungsaufbaus der
                        Website,
                    </li>
                    <li>
                        Sicherstellung einer reibungslosen Nutzung unserer
                        Website,
                    </li>
                    <li>
                        Auswertung der Systemsicherheit und -stabilität sowie
                    </li>
                    <li>zu weiteren administrativen Zwecken.</li>
                </ul>
                <p>
                    Die Verarbeitung Ihrer personenbezogenen Daten basiert auf
                    unserem berechtigten Interesse aus den vorgenannten Zwecken
                    zur Datenerhebung. Wir verwenden Ihre Daten nicht, um
                    Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten
                    sind nur die verantwortliche Stelle und ggf.
                    Auftragsverarbeiter.
                </p>
                <p>
                    Anonyme Informationen dieser Art werden von uns ggfs.
                    statistisch ausgewertet, um unseren Internetauftritt und die
                    dahinterstehende Technik zu optimieren.
                </p>
                <h2>Cookies</h2>
                <p>
                    Wie viele andere Webseiten verwenden wir auch so genannte
                    „Cookies“. Cookies sind kleine Textdateien, die von einem
                    Websiteserver auf Ihre Festplatte übertragen werden.
                    Hierdurch erhalten wir automatisch bestimmte Daten wie z. B.
                    IP-Adresse, verwendeter Browser, Betriebssystem und Ihre
                    Verbindung zum Internet.
                </p>
                <p>
                    Cookies können nicht verwendet werden, um Programme zu
                    starten oder Viren auf einen Computer zu übertragen. Anhand
                    der in Cookies enthaltenen Informationen können wir Ihnen
                    die Navigation erleichtern und die korrekte Anzeige unserer
                    Webseiten ermöglichen.
                </p>
                <p>
                    In keinem Fall werden die von uns erfassten Daten an Dritte
                    weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung
                    mit personenbezogenen Daten hergestellt.
                </p>
                <p>
                    Natürlich können Sie unsere Website grundsätzlich auch ohne
                    Cookies betrachten. Internet-Browser sind regelmäßig so
                    eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen
                    können Sie die Verwendung von Cookies jederzeit über die
                    Einstellungen Ihres Browsers deaktivieren. Bitte verwenden
                    Sie die Hilfefunktionen Ihres Internetbrowsers, um zu
                    erfahren, wie Sie diese Einstellungen ändern können. Bitte
                    beachten Sie, dass einzelne Funktionen unserer Website
                    möglicherweise nicht funktionieren, wenn Sie die Verwendung
                    von Cookies deaktiviert haben.
                </p>
                <h2>SSL-Verschlüsselung</h2>
                <p>
                    Um die Sicherheit Ihrer Daten bei der Übertragung zu
                    schützen, verwenden wir dem aktuellen Stand der Technik
                    entsprechende Verschlüsselungsverfahren (z. B. SSL) über
                    HTTPS.
                </p>
                <h2>Kontaktformular</h2>
                <p>
                    Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder
                    Kontaktformular mit uns in Kontakt, erteilen Sie uns zum
                    Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung.
                    Hierfür ist die Angabe einer validen E-Mail-Adresse
                    erforderlich. Diese dient der Zuordnung der Anfrage und der
                    anschließenden Beantwortung derselben. Die Angabe weiterer
                    Daten ist optional. Die von Ihnen gemachten Angaben werden
                    zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
                    Anschlussfragen gespeichert. Nach Erledigung der von Ihnen
                    gestellten Anfrage werden personenbezogene Daten automatisch
                    gelöscht.
                </p>
                <h2>Verwendung von Google Analytics</h2>
                <p>
                    Diese Website benutzt Google Analytics, einen
                    Webanalysedienst der Google Inc. (folgend: Google). Google
                    Analytics verwendet sog. „Cookies“, also Textdateien, die
                    auf Ihrem Computer gespeichert werden und die eine Analyse
                    der Benutzung der Webseite durch Sie ermöglichen. Die durch
                    das Cookie erzeugten Informationen über Ihre Benutzung
                    dieser Webseite werden in der Regel an einen Server von
                    Google in den USA übertragen und dort gespeichert. Aufgrund
                    der Aktivierung der IP-Anonymisierung auf diesen Webseiten,
                    wird Ihre IP-Adresse von Google jedoch innerhalb von
                    Mitgliedstaaten der Europäischen Union oder in anderen
                    Vertragsstaaten des Abkommens über den Europäischen
                    Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
                    die volle IP-Adresse an einen Server von Google in den USA
                    übertragen und dort gekürzt. Im Auftrag des Betreibers
                    dieser Website wird Google diese Informationen benutzen, um
                    Ihre Nutzung der Webseite auszuwerten, um Reports über die
                    Webseitenaktivitäten zusammenzustellen und um weitere mit
                    der Websitenutzung und der Internetnutzung verbundene
                    Dienstleistungen gegenüber dem Webseitenbetreiber zu
                    erbringen. Die im Rahmen von Google Analytics von Ihrem
                    Browser übermittelte IP-Adresse wird nicht mit anderen Daten
                    von Google zusammengeführt.
                </p>
                <p>
                    Die Zwecke der Datenverarbeitung liegen in der Auswertung
                    der Nutzung der Website und in der Zusammenstellung von
                    Reports über Aktivitäten auf der Website. Auf Grundlage der
                    Nutzung der Website und des Internets sollen dann weitere
                    verbundene Dienstleistungen erbracht werden. Die
                    Verarbeitung beruht auf dem berechtigten Interesse des
                    Webseitenbetreibers.
                </p>
                <p>
                    Sie können die Speicherung der Cookies durch eine
                    entsprechende Einstellung Ihrer Browser-Software verhindern;
                    wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website
                    vollumfänglich werden nutzen können. Sie können darüber
                    hinaus die Erfassung der durch das Cookie erzeugten und auf
                    Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer
                    IP-Adresse) an Google sowie die Verarbeitung dieser Daten
                    durch Google verhindern, indem sie das unter dem folgenden
                    Link verfügbare Browser-Plugin herunterladen und
                    installieren:{' '}
                    <a
                        href="http://tools.google.com/dlpage/gaoptout?hl=de"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Browser Add On zur Deaktivierung von Google Analytics
                    </a>
                    .
                </p>
                <p>
                    Zusätzlich oder als Alternative zum Browser-Add-On können
                    Sie das Tracking durch Google Analytics auf unseren Seiten
                    unterbinden, indem Sie{' '}
                    <a title="Google Analytics Opt-Out-Cookie setzen" href="#">
                        diesen Link anklicken
                    </a>
                    . Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert.
                    Damit wird die Erfassung durch Google Analytics für diese
                    Website und für diesen Browser zukünftig verhindert, so
                    lange das Cookie in Ihrem Browser installiert bleibt.
                </p>
                <h2>Verwendung von Adobe Typekit</h2>
                <p>
                    Wir setzen Adobe Typekit zur visuellen Gestaltung unserer
                    Website ein. Typekit ist ein Dienst der Adobe Systems
                    Software Ireland Ltd. der uns den Zugriff auf eine
                    Schriftartenbibliothek gewährt. Zur Einbindung der von uns
                    benutzten Schriftarten, muss Ihr Browser eine Verbindung zu
                    einem Server von Adobe in den USA aufbauen und die für
                    unsere Website benötigte Schriftart herunterladen. Adobe
                    erhält hierdurch die Information, dass von Ihrer IP-Adresse
                    unsere Website aufgerufen wurde. Weitere Informationen zu
                    Adobe Typekit finden Sie in den Datenschutzhinweisen von
                    Adobe, die Sie hier abrufen können:{' '}
                    <a
                        href="http://www.adobe.com/privacy/typekit.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.adobe.com/privacy/typekit.html
                    </a>
                </p>
                <h2>Verwendung von Google Maps</h2>
                <p>
                    Diese Webseite verwendet Google Maps API, um geographische
                    Informationen visuell darzustellen. Bei der Nutzung von
                    Google Maps werden von Google auch Daten über die Nutzung
                    der Kartenfunktionen durch Besucher erhoben, verarbeitet und
                    genutzt. Nähere Informationen über die Datenverarbeitung
                    durch Google können Sie{' '}
                    <a
                        href="http://www.google.com/privacypolicy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        den Google-Datenschutzhinweisen
                    </a>{' '}
                    entnehmen. Dort können Sie im Datenschutzcenter auch Ihre
                    persönlichen Datenschutz-Einstellungen verändern.
                </p>
                <p>
                    Ausführliche Anleitungen zur Verwaltung der eigenen Daten im
                    Zusammenhang mit Google-Produkten
                    <a
                        href="http://www.dataliberation.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {' '}
                        finden Sie hier
                    </a>
                    .
                </p>
                <h2>Eingebettete YouTube-Videos</h2>
                <p>
                    Auf einigen unserer Webseiten betten wir Youtube-Videos ein.
                    Betreiber der entsprechenden Plugins ist die YouTube, LLC,
                    901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine
                    Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung
                    zu Servern von Youtube hergestellt. Dabei wird Youtube
                    mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem
                    Youtube-Account eingeloggt sind, kann Youtube Ihr
                    Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern
                    Sie, indem Sie sich vorher aus Ihrem Youtube-Account
                    ausloggen.
                </p>
                <p>
                    Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies
                    ein, die Hinweise über das Nutzerverhalten sammeln.
                </p>
                <p>
                    Wer das Speichern von Cookies für das Google-Ad-Programm
                    deaktiviert hat, wird auch beim Anschauen von Youtube-Videos
                    mit keinen solchen Cookies rechnen müssen. Youtube legt aber
                    auch in anderen Cookies nicht-personenbezogene
                    Nutzungsinformationen ab. Möchten Sie dies verhindern, so
                    müssen Sie das Speichern von Cookies im Browser blockieren.
                </p>
                <p>
                    Weitere Informationen zum Datenschutz bei „Youtube“ finden
                    Sie in der Datenschutzerklärung des Anbieters unter:{' '}
                    <a
                        href="https://www.google.de/intl/de/policies/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.google.de/intl/de/policies/privacy/{' '}
                    </a>
                </p>
                <h2>
                    <strong>Änderung unserer Datenschutzbestimmungen</strong>
                </h2>
                <p>
                    Wir behalten uns vor, diese Datenschutzerklärung anzupassen,
                    damit sie stets den aktuellen rechtlichen Anforderungen
                    entspricht oder um Änderungen unserer Leistungen in der
                    Datenschutzerklärung umzusetzen, z.B. bei der Einführung
                    neuer Services. Für Ihren erneuten Besuch gilt dann die neue
                    Datenschutzerklärung.
                </p>
                <h2>
                    <strong>Fragen an den Datenschutzbeauftragten</strong>
                </h2>
                <p>
                    Wie kann der Datenschutzbeauftragte erreicht werden?
                </p>
                <p>
                    Entweder unter der unter oben genannten Post-Adresse oder per E-Mail-Adresse:
                    <br />
                    Email: <a href="mailto:dsb@bahai.de">dsb@bahai.de</a>
                    <br />
                    Nationaler Geistiger Rat der Bahá’i in Deutschland
                </p>
                <p>
                    Eppsteinerstr. 89
                    <br />
                    65719 Hofheim
                    <br />
                    info@bahai.de
                </p>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default Datenschutzerklaerung;
